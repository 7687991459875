// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-blog-index-js": () => import("/Users/jonasverhoelen/Documents/private/lpietzsch.github.io/src/blog/index.js" /* webpackChunkName: "component---src-blog-index-js" */),
  "component---src-blog-category-js": () => import("/Users/jonasverhoelen/Documents/private/lpietzsch.github.io/src/blog/category.js" /* webpackChunkName: "component---src-blog-category-js" */),
  "component---src-blog-post-js": () => import("/Users/jonasverhoelen/Documents/private/lpietzsch.github.io/src/blog/post.js" /* webpackChunkName: "component---src-blog-post-js" */),
  "component---src-pages-about-js": () => import("/Users/jonasverhoelen/Documents/private/lpietzsch.github.io/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-datenschutz-js": () => import("/Users/jonasverhoelen/Documents/private/lpietzsch.github.io/src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-hochzeit-16-07-2022-js": () => import("/Users/jonasverhoelen/Documents/private/lpietzsch.github.io/src/pages/hochzeit-16-07-2022.js" /* webpackChunkName: "component---src-pages-hochzeit-16-07-2022-js" */),
  "component---src-pages-impressum-js": () => import("/Users/jonasverhoelen/Documents/private/lpietzsch.github.io/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/Users/jonasverhoelen/Documents/private/lpietzsch.github.io/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/jonasverhoelen/Documents/private/lpietzsch.github.io/.cache/data.json")

